import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2b45f0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CutScreen = _resolveComponent("CutScreen")!
  const _component_SubmitTips = _resolveComponent("SubmitTips")!
  const _component_SbumitState = _resolveComponent("SbumitState")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.popupType === 'CutScreen')
      ? (_openBlock(), _createBlock(_component_CutScreen, {
          key: 0,
          onClose: _ctx.close
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (_ctx.popupType === 'submitTips')
      ? (_openBlock(), _createBlock(_component_SubmitTips, {
          key: 1,
          onClose: _ctx.close
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (_ctx.popupType === 'SbumitState')
      ? (_openBlock(), _createBlock(_component_SbumitState, {
          key: 2,
          onClose: _ctx.close,
          state: _ctx.state
        }, null, 8, ["onClose", "state"]))
      : _createCommentVNode("", true)
  ]))
}