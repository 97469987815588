
import { onlinePaging, detail, ResponseData, getExamThisTime, ExamThisTimeResponseDataList } from '@/api/buffer'
import { ElMessage } from 'element-plus'
import { useStore } from "vuex";
import dayjs from 'dayjs'
import { defineComponent, ref, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router';

export default defineComponent({
    setup() {
        const checked = ref<boolean>(false)
        const router = useRouter();
        const store = useStore();
        const examList = reactive({
            id: '',
            examData: {} as ExamThisTimeResponseDataList
        })
        function next(): void {
            if(!checked.value) {
                ElMessage.warning('请阅考前须知');
            } else {
                if(!examList.examData?.startTime) {
                    ElMessage.warning('今天没有考试');
                    return;
                }
                const examtime = dayjs(examList.examData.startTime).unix();
                const nowtime = dayjs().unix()
                if(examtime <= nowtime) {
                    router.push({name:'home',params:{id: examList.id}})
                } else {
                    ElMessage.warning('还没到考试时间');
                }
            }
        }
        async function getExamList() {
            const { code, data } = await getExamThisTime();
            if(code === 0) {
                if(data?.list?.length) {
                    examList.id = data.list[0].id;
                    examList.examData = data.list[0];
                    store.commit("changeExamId", examList.id);
                    // store.commit("changeExamId", '1356425140212076545');
                    // detail({id: examList.id},false,'json',true).then(res => {
                    //     if(res.code === 0) {
                    //     }
                    // });
                }
            }
        }
        getExamList();
        async function getExamData() {
            try {
                const { code, data } = await onlinePaging({current: 1,size: 10},false,'json',true);
                if(code === 0) {
                    if(data?.records?.length) {
                        examList.id = data.records[0].id;
                        detail({id: data?.records[0].id},false,'json',true).then(res => {
                            if(res.code === 0) {
                            }
                        });
                    }
                }
            } catch {
            }
        }
        function isChecked(val: boolean): void {
            checked.value = val;
        }
        return {
            next,
            checked,
            isChecked,
            ...toRefs(examList)
        }
    },
})
