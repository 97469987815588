import axios from '@/unit/http/request'
import { resType } from '@/unit/type'
export function onlinePaging<T = ResponseData>(params: PagingParams,loading?: boolean, type?: string,setToken?: boolean): Promise<T> {
    return axios.post('/exam/api/exam/exam/online-paging',params,loading , type, setToken)
}

export interface PagingParams {
    current: number,
    orderBy?: string,
    params?: paramsData,
    size: number,
}
export interface paramsData {
    content: string,
	createTime: string,
	endTime: string,
	id: string,
	joinType: number,
	level: number,
	openType: number,
	qualifyScore: number,
	startTime: string,
	state: number,
	timeLimit: boolean,
	title: string,
	totalScore: number,
	totalTime: number,
	updateTime: string
}

export interface ResponseData extends resType {
    data?: ResData,
}
export interface ResData {
    current: number,
	hitCount?: boolean,
	pages: number,
	records?: Array<RecordsData>,
	searchCount?: boolean,
	size: number,
	total: number
}
export interface RecordsData {
    content: string,
    createTime: string,
    endTime: string,
    id: string,
    joinType: number,
    level: number,
    openType: number,
    qualifyScore: number,
    startTime: string,
    state: number,
    timeLimit: true,
    title: string,
    totalScore: number,
    totalTime: number,
    updateTime: string
}

export function detail<T = DetailResponseData>(params: DetailParams,loading?: boolean, type?: string,setToken?: boolean): Promise<T> {
    return axios.post('/exam/api/exam/exam/detail',params,loading , type, setToken)
}
export interface DetailParams {
    id: string
}
export interface DetailResponseData extends resType  {
    data?: DetailResData,
}
export interface DetailResData {
    content: string,
    createTime: string,
    departIds: [],
    endTime: string,
    id: string,
    joinType: number,
    level: number,
    openType: number,
    qualifyScore: number,
    repoList: Array<DetailResDataRepoList>,
    startTime: string,
    state: number,
    timeLimit: boolean,
    title: string,
    totalScore: number,
    totalTime: number,
    updateTime: string
}
export interface DetailResDataRepoList {
    examId: string,
    id: string,
    judgeCount: number,
    judgeScore: number,
    multiCount: number,
    multiScore: number,
    radioCount: number,
    radioScore: number,
    repoId: string,
    totalJudge: number,
    totalMulti: number,
    totalRadio: number
}

export function getExamThisTime<T = ExamThisTimeResponseData>(): Promise<T> {
    return axios.post('/exam/api/exam/exam/get_exam_this_time',{}, false, 'json', true)
}
export interface ExamThisTimeResponseData extends resType {
    data: {
        list: Array<ExamThisTimeResponseDataList>
    } 
}
export interface ExamThisTimeResponseDataList {
    content: string,
	createTime: string,
	endTime: string,
	id: string,
	joinType: number,
	level: number,
	openType: number,
	qualifyScore: number,
	startTime: string,
	state: number,
	timeLimit: boolean,
	title: string,
	totalScore: number,
	totalTime: number,
	updateTime: string
}
