
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
    props: {
        state: {
            type: String,
            default: 'success'
        }
    },
    setup() {
        const usetDataString = sessionStorage.getItem('userData') + ''
        const userData = reactive({
            user: JSON.parse(usetDataString)
        })
        return {
            ...toRefs(userData),
        }
    },
})
