import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import '@/assets/style/reset.less';
import { doalert } from './plugin/index'
const VueApp = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    VueApp.component(key, component)
  }
  // 获取原型
const prototype = VueApp.config.globalProperties

// 绑定参数
prototype.name = 'Jerry'
  VueApp.config.globalProperties.$doalert = doalert
VueApp
.use(store)
.use(router)
.use(ElementPlus)
.mount('#app')
