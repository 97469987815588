
import { defineComponent } from 'vue'

export default defineComponent({
    setup(props, ctx) {
        function clear(): void {
            ctx.emit('close',{type: 'start'})
        }
        return {
            clear
        }
    },
})
