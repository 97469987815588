
import { defineComponent, reactive, computed, toRefs } from "vue";
import { useStore } from "vuex";
 type ViewData = {
  timeString: string,
  time1: any,
  completedCount: any,
  topicTotal: any,
  topieData: any,
  fontSize: any,
  examQuestionList: any
 }
export default defineComponent({
  setup() {
    const store = useStore();
    const viewData = reactive<ViewData>({
      timeString: "00:00:00", //倒计时
      time1: null,
      completedCount: computed(() => {
        return store.state.completedCount;
      }),
      examQuestionList: computed(() => {
        return store.getters.getExamQuestionList;
      }),
      topicTotal: computed(() => {
        return store.state.topicTotal;
      }),
      topieData: computed(() => {
        return store.state.topieData;
      }),
      fontSize: computed(() => {
        return store.state.fontSize;
      }),
    });
    //字体缩小
    function lessen(): void {
      if (viewData.fontSize - 2 < 14) {
        store.commit("changeFontSize", 14);
      } else {
        store.commit("changeFontSize", viewData.fontSize - 2);
      }
    }
    //字体放大
    function enlarge(): void {
      if (viewData.fontSize + 2 > 36) {
        store.commit("changeFontSize", 36);
      } else {
        store.commit("changeFontSize", viewData.fontSize + 2);
      }
    }
    window.clearInterval(viewData.time1);
    viewData.time1 = null;
    // 倒计时
    function countDown(): void {
      window.clearInterval(viewData.time1);
      viewData.time1 = null;
      let hh = "00",
        mm = "00",
        ss = "00";
      viewData.time1 = setInterval(() => {
        viewData.examQuestionList.leftSeconds--;
        hh = Math.floor(viewData.examQuestionList.leftSeconds / 3600) + "";
        mm = Math.floor((viewData.examQuestionList.leftSeconds % 3600) / 60) + "";
        ss = (viewData.examQuestionList.leftSeconds % 60) + "";
        let str = `${+hh < 10 ? "0" : ""}${hh}:${+mm < 10 ? "0" : ""}${mm}:${
          +ss < 10 ? "0" : ""
        }${ss}`;
        viewData.timeString = str;
        if(!viewData.examQuestionList?.leftSeconds) {
          window.clearInterval(viewData.time1);
          viewData.time1 = null;
          viewData.timeString = '00:00:00';
        }
      }, 1000);
    }
    return {
        lessen,
        enlarge,
        countDown,
        ...toRefs(viewData),
    }
  },
});
