import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/login.vue'
import BufferView from '../views/buffer.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/buffer',
    name: 'buffer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: BufferView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to,from, next) => {
  if(to.path !== '/login') {
    let userData = sessionStorage.getItem('userData');
    if(userData) {
      const data = JSON.parse(userData)
      if(data.token) {
        next();
      } else {
        next({path:'/login'})
      }
    } else {
      next({path:'/login'})
    }
  } else {
    next();
  }
})
export default router
