
import { defineComponent, reactive, toRefs } from 'vue'
import router from '@/router'
import { useStore } from "vuex";

export default defineComponent({
    setup() {
        const store = useStore();
        const usetDataString = sessionStorage.getItem('userData') + ''
        const userData = reactive({
            user: JSON.parse(usetDataString)
        })
        const quit = (): void => {
            router.push('/')
            sessionStorage.setItem('userData','')
            store.commit('closeExam');
            console.log('退出')
        }
        const loginOut = quit
        return {
            loginOut,
            ...toRefs(userData),
        }
    },
})
