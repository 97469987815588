import { ElMessage } from 'element-plus'
const HttpError = {
    Error400: '错误请求!',
    Error401: '令牌过期,请重新登录!',
    Error403: '没有API接口访问权限!',
    Error404: '访问服务器不存在的API!',
    Error405: '网络请求错误，请求方法未允许!',
    Error408: '请求超时!',
    Error500: '服务器端出错，请联系管理员!',
    Error501: '网络未实现!',
    Error502: '网络错误!',
    Error503: '服务不可用，服务器暂时过载或维护!',
    Error504: '网络超时!',
    Error505: 'http版本不支持该请求!',
    ErrorUnknown: '未知错误!',
}
export function checkStatus(status: number): void {
    switch (status) {
      case 400:
        ElMessage(HttpError.Error400);
        break;
      case 401:
        // TODO 此处需要清空缓存的登录信息
        ElMessage(HttpError.Error401);
        break;
      case 403:
        ElMessage(HttpError.Error403);
        break;
      case 404:
        ElMessage(HttpError.Error404);
        break;
      case 405:
        ElMessage(HttpError.Error405);
        break;
      case 408:
        ElMessage(HttpError.Error408);
        break;
      case 500:
        ElMessage(HttpError.Error500);
        break;
      case 501:
        ElMessage(HttpError.Error501);
        break;
      case 502:
        ElMessage(HttpError.Error502);
        break;
      case 503:
        ElMessage(HttpError.Error503);
        break;
      case 504:
        ElMessage(HttpError.Error504);
        break;
      case 505:
        ElMessage(HttpError.Error505);
        break;
      default :
        ElMessage(HttpError.ErrorUnknown);
        break;
    }
  }