
import { defineComponent, reactive, toRefs, ref, computed } from "vue";
import { createPaper, paperDetail, fillAnswer, handExam } from "@/api/home";
import { useStore } from "vuex";
import router from '@/router'
import NavTop from "@/components/navTop.vue";
import Popup from "@/components/tips/popup.vue";
import LeftTopic from "./components/leftTopit.vue";
import Content from "./components/content.vue";
import { PopupEmitType } from "@/unit/type";
import RightTips from "./components/rightTips.vue";
type ExamIds = {
  paperId: string,
  quId: string
}

export default defineComponent({
  name: "HomeView",
  components: {
    NavTop,
    Popup,
    LeftTopic,
    RightTips,
    Content
  },
  setup(props) {
    const store = useStore();
    const rightTips = ref<InstanceType<typeof RightTips>>();
    const content = ref<InstanceType<typeof Content>>();
    const homeData = reactive({
      examId: computed(() => {return store.state.examId}),
      popupShow: true,
      popupType: "CutScreen", // CutScreen:切屏弹窗  submitTips提交 SbumitState提交状态
      state: 'success', // success lose
      examData: computed(() => { return store.state.examData; }),
      paperId: computed(() => { return store.state.paperId; }),
      questionData: computed(() => { return store.state.questionData; }),
    });
    const examIds = reactive<ExamIds>({
      paperId: '',
      quId: ''
    })
    // 获取题目列表
    async function getData() {
      // 获取试卷id
      await createPaper({examId: homeData.examId},false,'json',true).then(res => {
        if(res.code === 0) {
          examIds.paperId = res.data.id;
          // content.value?.setIds(examIds.paperId,'')
          store.commit('changePaperId',examIds.paperId);
          //获取试卷列表
          paperDetail({id: res.data.id},false,'json',true).then(res2 => {
            if(res2.code === 0) {
              let dataList = res2.data;
              examIds.quId = res2.data.radioList[0].quId;
              store.dispatch('clickQuestionChange',{data: res2.data.radioList[0],index: 0,type: 'radioList'});
              store.commit('changeOldQuestionData',{topicIndex:0,questionType: 'radioList'})
              // content.value?.setIds('',res2.data.radioList[0].quId);
              let topicTotal = 0;
              if(dataList.radioList.length) {
                topicTotal += dataList.radioList.length
                for(let i in dataList.radioList) {
                  dataList.radioList[i].isSccomplish = false;
                  dataList.radioList[i].isSign = false;
                } 
                dataList.radioList[0].isSccomplish = true;
              }
              if(dataList.multiList.length) {
                topicTotal += dataList.multiList.length
                for(let i in dataList.multiList) {
                  dataList.multiList[i].isSccomplish = false;
                  dataList.multiList[i].isSign = false;
                } 
              }
              if(dataList.judgeList.length) {
                topicTotal += dataList.judgeList.length
                for(let i in dataList.judgeList) {
                  dataList.judgeList[i].isSccomplish = false;
                  dataList.judgeList[i].isSign = false;
                } 
              }
              store.commit('changeTopicTotal',topicTotal)
              store.commit('changeTopitData',res2.data)
            }
          });
        } else if(res.code === 10010002) {

        }
      });
    }
    //监听切屏次数
    const cutScreenCount = (): void => {
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState == "hidden") {
          //这里是切出去时间
          return;
        }
        store.commit("changeCutScreenCount");
        if(store.state.cutScreenCount >= 10) {  //
        	// alert("你将被强制提交试卷")
          // 强制交卷
          handExam({id: homeData.paperId}, true,'json',true).then(res => {
            if(res.code === 0) {
              homeData.popupType = "SbumitState";
              homeData.state = "success";
              homeData.popupShow = true;
              store.commit('closeExam');
            } else {
              homeData.popupType = "SbumitState";
              homeData.state = "lose";
              homeData.popupShow = true;
            }
          })
        }
      });
    };
    //提交试卷
    function submitPaper(): void {
      let answersList = []
      for(let i in homeData.examData?.answerList) {
        if(homeData.examData?.answerList[i].checked) {
          answersList.push(homeData.examData?.answerList[i].id)
        }
      }
      if(answersList.length) {
        store.dispatch('changeCompletedlist',homeData.questionData.quId)
        fillAnswer({answer: "",answers: answersList,paperId:homeData.paperId,quId:homeData.questionData.quId},false,'json',true)
      }
      homeData.popupShow = true;
      homeData.popupType = "submitTips";
    }
    //弹窗的返回
    function close(data: PopupEmitType): void {
      homeData.popupShow = false;
      homeData.popupType = "";
      if (data.type === "submit") {
        handExam({id: homeData.paperId}, true,'json',true).then(res => {
          if(res.code === 0) {
            homeData.popupType = "SbumitState";
            homeData.state = "success";
            homeData.popupShow = true;
            store.commit('closeExam');
            setTimeout(() => {
              router.push('/')
            }, 3000);
          } else {
            homeData.popupType = "SbumitState";
            homeData.state = "lose";
            homeData.popupShow = true;
            setTimeout(() => {
              homeData.popupShow = false;
            }, 3000);
          }
        })
        // 点击确认提交试卷
        //退出全屏
        document.exitFullscreen();
      } else if (data.type === "start") {
        //已知切屏限制
        //进入全屏
        document.documentElement.requestFullscreen();
        getData();
        cutScreenCount();
        // document.onkeydown = function (ev){
        //   ev.preventDefault();
        //   return false;
        // }
        rightTips.value?.countDown();
        // countDown();
      }
    }
    return {
      close,
      submitPaper,
      rightTips,
      content,
      ...toRefs(homeData),
    };
  },
});
