import instance from './index'
import qs from 'qs'
import { ElLoading } from 'element-plus'
import { AxiosRequestHeaders } from 'axios'
import { fa } from 'element-plus/lib/locale';

let loading : any;
let requestNum: number = 0;
const addLoading = () => {
    requestNum++;
    if(requestNum === 1) {
        loading = ElLoading.service({
            lock: true,
            text: '加载中……',
            background: 'rgba(0, 0, 0, 0.7)',
        })
    }
}
const cancelLoading = () => {
    // 取消loading 如果pending请求数量等于0，关闭loading
    requestNum--;
    if (requestNum === 0) loading?.close();
}

const setHeaderType = (type: string, setToken?: boolean): AxiosRequestHeaders => {
    let headerObj:any = {}
    if(type === 'formData') {
        headerObj["Content-Type"] = "multipart/form-data; boundary=----WebKitFormBoundarycmB1Ttwgif7GRLz2";
    } else {
        headerObj["Content-Type"] = "application/json;charset=UTF-8";
    };
    if(setToken) {
        let userData:any = sessionStorage.getItem('userData')? sessionStorage.getItem('userData') : '{}';
        let token = JSON.parse(userData).token
        headerObj["token"] = token;
    }
    return headerObj
}

/*
* type //传送数据方式默认json
 */
const post = function (url: string, params: any,loading?: Boolean, type?: string, setToken?: boolean): any {
    if(loading) addLoading();
    let signHeader: AxiosRequestHeaders;
    if(type) signHeader = setHeaderType(type, setToken);
    return new Promise((resolve, reject) => {
        instance({
            url: url,
            method: 'post',
            data: params,
            headers: signHeader,
        }).then(res => {
            cancelLoading();
            resolve(res);
        }).catch(err => {
            cancelLoading();
            reject(err);
        })
    })
}
const get = function (url: string, params: any,loading?: Boolean, type?: string, setToken?: boolean): any {
    if(loading) addLoading();
    let signHeader: AxiosRequestHeaders;
    if(type) signHeader = setHeaderType(type, setToken);
    return new Promise((resolve, reject) => {
        instance({
            url: url,
            method: 'get',
            params: params,
            headers: signHeader,
        }).then(res => {
            cancelLoading();
            resolve(res);
        }).catch(err => {
            cancelLoading();
            reject(err);
        })
    })
}
const axios: any = {
    get: get,
    post: post
}
export default axios