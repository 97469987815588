
import { LoginParams, login, ResponseData } from '@/api/login'
import router from '@/router'
import { defineComponent,reactive, getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus'

export default defineComponent({
    setup(props, ctx) {
        const instance = getCurrentInstance();
        // console.log('778899',instance)
        const loginData = reactive<LoginParams>({
            username: '',
            password: ''
        })
        function register(): void {
            if(!loginData.username) {
                ElMessage({
                    message: '用户名不能为空',
                    type: 'warning',
                });
                return;
            }
            if(!loginData.password) {
                ElMessage({
                    message: '密码不能为空',
                    type: 'warning',
                });
                return;
            }
            login(loginData, true,'').then((res: ResponseData) => {
                if(res.code === 0) {
                    sessionStorage.setItem('userData',JSON.stringify(res.data));
                    ElMessage({
                        message: '登录成功！',
                        type: 'success',
                        duration: 1500
                    });
                    setTimeout(()=> {
                        router.push('/buffer')
                    }, 1000)
                } else {
                    ElMessage({
                        message: res.msg,
                        type: 'warning',
                    })
                }
            })
        }
        function aaaa2(): void{
            // instance.proxy.$doalert()
        }
        return {
            loginData,
            register,
            aaaa2,
        }
    },
})
