import { createStore } from 'vuex'
import { paperDetailData, QuDetailData, quDetail, fillAnswer } from '@/api/home'
export default createStore({
  state: {
    cutScreenCount: 0, //切屏次数
    topicTotal: 0, //题目总数
    completedCount: 0, //完成的数量
    completedList: [] as number[], // 完成了的题目数组
    examData: {} as QuDetailData, // 当前题目
    examQuestionList: {} as paperDetailData, //题目列表
    fontSize: 24, // 字体大小
    examId: '', // 考试id
    paperId: 0, // 试卷id
    questionData: {
      quId: 0,  // 题目id
      topicIndex: 0,  // 题目坐标
      questionType: 'radioList',  // 题目类型
      isEnd: false, // 是否是最后一题
      isStart: true,
    },
    oldQuestionData: { // 切换提目前的数据
      topicIndex: 0,  // 题目坐标
      questionType: 'radioList',  // 题目类型
    }
  },
  getters: {
    getExamQuestionList: state => {
      return state.examQuestionList
    }
  },
  mutations: {
    changeExamId(state, data) {
      state.examId = data
    },
    changeOldQuestionData(state, data) {
      state.oldQuestionData.topicIndex = data.topicIndex;
      state.oldQuestionData.questionType = data.questionType;
    },
    changePaperId(state, data) {
      state.paperId = data
    },
    changeQuId(state,data) {
      state.questionData.quId = data;
    },
    changeTopicIndex(state,data) {
      state.questionData.topicIndex = data;
    },
    changeCutScreenCount(state) {
      state.cutScreenCount ++;
    },
    changeTopicTotal(state, data) {
      state.topicTotal = data;
    },
    changeCompletedCount(state, data) {
      state.completedCount = data;
    },
    changeTopitData(state, data) {
      state.examQuestionList = data;
    },
    changeFontSize(state,data) {
      state.fontSize = data;
    },
    closeExam(state,data) {
      state.examQuestionList = {} as paperDetailData;
      state.examData = {} as QuDetailData;
      state.paperId = 0;
      state.topicTotal = 0;
      state.completedCount = 0;
      state.examId = '0';
    },
    clickQuestionChange(state, data) {
      state.examQuestionList = data;
    },
    questionChange(state, data) {
      state.questionData.quId = data.data.quId;
      state.questionData.topicIndex = data.index;
      state.questionData.questionType = data.type;
    },
    examIndex(state, data) {
      state.questionData.isEnd = data.isEnd;
      state.questionData.isStart = data.isStart;
    },
    changeExamData(state, data) {
      state.examData = data;
    }
  },
  actions: {
    changeCompletedlist({ commit, state },data) {
      let num = state.completedList.indexOf(data);
      if(num == -1) {
        state.completedList.push(data);
        commit('changeCompletedCount',state.completedList.length)
      }
    },
    clickQuestionChange({ commit, state, dispatch },data) {
      if(data.data.quId != state.questionData.quId) {
        if(state.examData?.answerList?.length) { // 判断是否要提交题目答案
          let answersList = []
          for(let i in state.examData?.answerList) {
            if(state.examData?.answerList[i].checked) {
              answersList.push(state.examData?.answerList[i].id)
            }
          }
          if(answersList.length) {
            dispatch('changeCompletedlist',state.questionData.quId)
            let examData = {
              index: state.oldQuestionData.topicIndex,
              type: state.oldQuestionData.questionType,
            }
            fillAnswer({answer: "",answers: answersList,paperId:state.paperId,quId:state.questionData.quId},false,'json',true).then(res => {
              if(res.code === 0) {
                let list = [];
                if(examData.type === 'radioList') {
                  list = state.examQuestionList.radioList;
                  list[examData.index].answered = true;
                  state.examQuestionList.radioList = list;
                } else if(examData.type === 'multiList') {
                  list = state.examQuestionList.multiList;
                  list[examData.index].answered = true;
                  state.examQuestionList.multiList = list;
                } else if(examData.type === 'judgeList') {
                  list = state.examQuestionList.judgeList;
                  list[examData.index].answered = true;
                  state.examQuestionList.judgeList = list;
                }
              }
            })
          }
        }
        commit('questionChange',data)
        commit('changeOldQuestionData',{topicIndex:data.index,questionType:data.type});
        quDetail({paperId: state.paperId,quId: state.questionData.quId},false,'json',true).then(res => {
          if(res.code === 0) {
            let examDataDetail = {...res.data};
            if(examDataDetail.answerList?.length) {
              for(let i in examDataDetail?.answerList) {
                examDataDetail.answersList?.push(false);
              }
            }
            state.examData = examDataDetail;
          }
        });
      }
    },
    prevQuestion({ commit, state, dispatch }) { // 上一题
      let examData = {quId:0} as any;
      if(state.questionData.questionType === 'radioList') { // 单选题
        if(state.questionData.topicIndex > 0) { // 当前不是第一题
          state.questionData.isEnd = false;
          state.questionData.isStart = false;
          state.questionData.topicIndex --;
          for(let i in state.examQuestionList.radioList) {
            state.examQuestionList.radioList[i].isSccomplish = false;
          }
          examData = state.examQuestionList.radioList[state.questionData.topicIndex];
          if(state.questionData.topicIndex == 0) {
            state.questionData.isStart = true;
          }
          state.examQuestionList.radioList[state.questionData.topicIndex].isSccomplish = true;
          // commit('changeQuId',state.examQuestionList.radioList[state.questionData.topicIndex].quId);
        } else {
          state.questionData.isStart = true;
        }
      } else if(state.questionData.questionType == 'multiList') { // 多选题
        if(state.questionData.topicIndex > 0) {
          state.questionData.isEnd = false;
          state.questionData.isStart = false;
          state.questionData.topicIndex --;
          for(let i in state.examQuestionList.multiList) {
            state.examQuestionList.multiList[i].isSccomplish = false;
          }
          examData = state.examQuestionList.multiList[state.questionData.topicIndex];
          state.examQuestionList.multiList[state.questionData.topicIndex].isSccomplish = true;
          // commit('changeQuId',state.examQuestionList.multiList[state.questionData.topicIndex].quId);
        } else if(state.questionData.topicIndex <= 0) {
          if(state.examQuestionList.radioList.length) {
            state.questionData.topicIndex = state.examQuestionList.radioList.length - 1;
            state.questionData.questionType = 'radioList';
            state.questionData.isStart = false;
            state.questionData.isEnd = false;
            if(state.questionData.topicIndex <= 0) {
              state.questionData.isStart = true;
            }
            examData = state.examQuestionList.radioList[state.questionData.topicIndex];
            for(let i in state.examQuestionList.multiList) {
              state.examQuestionList.multiList[i].isSccomplish = false;
            }
            state.examQuestionList.radioList[state.questionData.topicIndex].isSccomplish = true;
            // commit('changeQuId',state.examQuestionList.radioList[state.questionData.topicIndex].quId);
          } else {
            state.questionData.isStart = true;
            state.questionData.isEnd = false;
          }
        }
      } else if(state.questionData.questionType == 'judgeList') {  // 判断题
        if(state.questionData.topicIndex > 0) { 
          state.questionData.isEnd = false;
          state.questionData.isStart = false;
          state.questionData.topicIndex --;
          for(let i in state.examQuestionList.judgeList) {
            state.examQuestionList.judgeList[i].isSccomplish = false;
          }
          examData = state.examQuestionList.judgeList[state.questionData.topicIndex];
          state.examQuestionList.judgeList[state.questionData.topicIndex].isSccomplish = true;
          // commit('changeQuId',state.examQuestionList.judgeList[state.questionData.topicIndex].quId);
        } else if(state.questionData.topicIndex <= 0) {
          if(state.examQuestionList.multiList.length) {
            state.questionData.topicIndex = state.examQuestionList.multiList.length - 1;
            state.questionData.questionType = 'multiList';
            state.questionData.isEnd = false;
            state.questionData.isStart = false;
            for(let i in state.examQuestionList.judgeList) {
              state.examQuestionList.judgeList[i].isSccomplish = false;
            }
            examData = state.examQuestionList.multiList[state.questionData.topicIndex];
            state.examQuestionList.multiList[state.questionData.topicIndex].isSccomplish = true;
            // commit('changeQuId',state.examQuestionList.multiList[state.questionData.topicIndex].quId);
          } else {
            if(state.examQuestionList.radioList.length) {
              state.questionData.topicIndex = state.examQuestionList.radioList.length - 1;
              state.questionData.questionType = 'radioList';
              state.questionData.isEnd = false;
              state.questionData.isStart = false;
              if(state.questionData.topicIndex <= 0) {
                state.questionData.isStart = true;
              }
              for(let i in state.examQuestionList.judgeList) {
                state.examQuestionList.judgeList[i].isSccomplish = false;
              }
              examData = state.examQuestionList.radioList[state.questionData.topicIndex];
              state.examQuestionList.radioList[state.questionData.topicIndex].isSccomplish = true;
              // commit('changeQuId',state.examQuestionList.radioList[state.questionData.topicIndex].quId);
            } else {
              state.questionData.isStart = true;
              state.questionData.isEnd = false;
            }
          }
        }
      }
      let obj = {
        data: examData,
        index: state.questionData.topicIndex,
        type: state.questionData.questionType
      }
      if(examData?.quId) dispatch('clickQuestionChange', obj);
    },
    nextQuestion({ commit, state, dispatch }) {
      let examData = {quId:0} as any;
      if(state.questionData.questionType === 'radioList') {
        if(state.questionData.topicIndex < (state.examQuestionList.radioList.length - 1)) {
          state.questionData.isEnd = false;
          state.questionData.isStart = false;
          state.questionData.topicIndex ++;
          for(let i in state.examQuestionList.radioList) {
            state.examQuestionList.radioList[i].isSccomplish = false;
          }
          examData = state.examQuestionList.radioList[state.questionData.topicIndex];
          state.examQuestionList.radioList[state.questionData.topicIndex].isSccomplish = true;
          // commit('changeQuId',state.examQuestionList.radioList[state.questionData.topicIndex].quId);
        } else {
          if(state.examQuestionList.multiList.length) {
            state.questionData.isEnd = false;
            state.questionData.isStart = false;
            state.questionData.topicIndex = 0;
            state.questionData.questionType = 'multiList';
            for(let i in state.examQuestionList.radioList) {
              state.examQuestionList.radioList[i].isSccomplish = false;
            }
            examData = state.examQuestionList.multiList[state.questionData.topicIndex];
            state.examQuestionList.multiList[state.questionData.topicIndex].isSccomplish = true;
            // commit('changeQuId',state.examQuestionList.multiList[state.questionData.topicIndex].quId);
          } else {
            if(state.examQuestionList.judgeList.length) {
              state.questionData.isStart = false;
              state.questionData.isEnd = false;
              state.questionData.topicIndex = 0;
              state.questionData.questionType = 'judgeList';
              for(let i in state.examQuestionList.radioList) {
                state.examQuestionList.radioList[i].isSccomplish = false;
              }
              examData = state.examQuestionList.judgeList[state.questionData.topicIndex];
              state.examQuestionList.judgeList[state.questionData.topicIndex].isSccomplish = true;
              // commit('changeQuId',state.examQuestionList.judgeList[state.questionData.topicIndex].quId);
            } else {
              state.questionData.isStart = false;
              state.questionData.isEnd = true;
            }
          }
        }
      } else if(state.questionData.questionType == 'multiList') {
        if(state.questionData.topicIndex < (state.examQuestionList.multiList.length - 1)) {
          state.questionData.isEnd = false;
          state.questionData.isStart = false;
          state.questionData.topicIndex ++;
          for(let i in state.examQuestionList.multiList) {
            state.examQuestionList.multiList[i].isSccomplish = false;
          }
          examData = state.examQuestionList.multiList[state.questionData.topicIndex];
          state.examQuestionList.multiList[state.questionData.topicIndex].isSccomplish = true;
          // commit('changeQuId',state.examQuestionList.multiList[state.questionData.topicIndex].quId);
        } else {
          if(state.examQuestionList.judgeList.length) {
            state.questionData.isEnd = false;
            state.questionData.isStart = false;
            state.questionData.topicIndex = 0;
            state.questionData.questionType = 'judgeList';
            for(let i in state.examQuestionList.multiList) {
              state.examQuestionList.multiList[i].isSccomplish = false;
            }
            examData = state.examQuestionList.judgeList[state.questionData.topicIndex];
            state.examQuestionList.judgeList[state.questionData.topicIndex].isSccomplish = true;
            // commit('changeQuId',state.examQuestionList.judgeList[state.questionData.topicIndex].quId);
          } else {
            state.questionData.isEnd = true;
            state.questionData.isStart = false;
          }
        }
      } else if(state.questionData.questionType == 'judgeList') {
        if(state.questionData.topicIndex < (state.examQuestionList.judgeList.length - 1)) {
          state.questionData.isStart = false;
          state.questionData.isEnd = false;
          state.questionData.topicIndex ++;
          for(let i in state.examQuestionList.judgeList) {
            state.examQuestionList.judgeList[i].isSccomplish = false;
          }
          examData = state.examQuestionList.judgeList[state.questionData.topicIndex];
          state.examQuestionList.judgeList[state.questionData.topicIndex].isSccomplish = true;
          if(state.questionData.topicIndex = (state.examQuestionList.judgeList.length - 1)) {
            state.questionData.isEnd = true;
            state.questionData.isStart = false;
          }
          // commit('changeQuId',state.examQuestionList.judgeList[state.questionData.topicIndex].quId);
        }
      }
      let obj = {
        // data: state.examQuestionList.radioList[state.questionData.topicIndex],
        data: examData,
        index: state.questionData.topicIndex,
        type: state.questionData.questionType
      }
      if(examData?.quId) dispatch('clickQuestionChange', obj);
    }
  },
  modules: {
  }
})
