<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
/* // 修改选择框颜色 */
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #50212F !important;
  background-color: #50212F !important;
}
.el-checkbox.el-checkbox--large .el-checkbox__inner {
  height: 25px !important;
  width: 25px !important;
}
.el-checkbox.el-checkbox--large .el-checkbox__label {
  color: #50212F !important;
  font-size: 22px !important;
  font-weight: 500 !important;
}
.el-checkbox.el-checkbox--large .el-checkbox__inner:hover {
  border: 1px solid #50212F !important;
}
.el-checkbox {
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border: #50212F !important;
}
.el-checkbox__inner::after {
  left: 8px !important;
  height: 14px !important;
  width: 6px !important;
  top: 0px !important;
  border: none !important;
  border-right: 2px solid #FFFFFF !important;
  border-bottom: 2px solid #FFFFFF !important;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #50212F !important;
}
</style>
