
import { defineComponent } from 'vue'

export default defineComponent({
    setup(props, ctx) {
        function clear(): void {
            ctx.emit('close',{type: 'submit'})
        }
        function cancel(): void {
            ctx.emit('close',{type: ''})
        }
        return {
            clear,
            cancel,
        }
    },
})
