import axios from '@/unit/http/request'
import { resType } from '@/unit/type'
export function login<T = ResponseData>(params: LoginParams,loading?: boolean, type?: string): Promise<T> {
    return axios.post('/exam/api/sys/user/login',params,loading , type)
}

export interface ResponseData extends resType {
    data?: UserData,
}

export interface LoginParams {
    username: string,
    password: string,
}
export interface UserData {
    createTime: string,
    departId: string,
    id: string,
    realName: string,
    roleIds: string,
    roles: Array<string>,
    state: number,
    token: string,
    updateTime: string,
    userName:string,
}