import { App as VM } from 'vue'
import { ElIcon, } from 'element-plus'
const plugins = [
    ElIcon,
]
export const vantPlugins = {
    install: function(vm: VM): void {
      plugins.forEach(item => {
        vm.component(item.name, item);
      });
    },
  };
export const doalert = function() {
    alert('的风格萨芬')
}