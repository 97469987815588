import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { checkStatus } from './checkStatus'
import { ElLoading, ElMessage } from 'element-plus'
import router from '@/router'

// 定义接口
interface PendingType {
    url?: string;
    method?: Method | string;
    params: any;
    data: any;
    cancel: any;
}
// 取消重复请求
const pending: Array<PendingType> = [];
const CancelToken = axios.CancelToken;
// 移除重复请求
const removePending = (config: AxiosRequestConfig) => {
    for (const key in pending) {
        const item: number = +key;
        const list: PendingType = pending[key];
        // 当前请求在数组中存在时执行函数体
        if (list.url === config.url && list.method === config.method && JSON.stringify(list.params) === JSON.stringify(config.params) && JSON.stringify(list.data) === JSON.stringify(config.data)) {
            // 执行取消操作
            list.cancel('操作太频繁，请稍后再试');
            // 从数组中移除记录
            pending.splice(item, 1);
        }
    }
}

const baseUrl = process.env.VUE_APP_API_URL
// const baseUrl =  'http://www.wy56.com/keyi-exam/'
/* 实例化请求配置 */
const instance = axios.create({
    headers: {
        //php 的 post 传输请求头一定要这个 不然报错 接收不到值
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin-Type": '*',
    },
    // 请求时长
    timeout: 1000 * 30,
    // 请求的base地址 TODO:这块以后根据不同的模块调不同的api
    baseURL: baseUrl,
    // 表示跨域请求时是否需要使用凭证
    // withCredentials: false,
})
instance.interceptors.request.use((config: AxiosRequestConfig) => {
    // config.headers = Object.assign(config.headers,{})
    // return
    // if(config.url !== '/exam/api/sys/user/login') {
    // }
    // Object.assign(config.headers,{"Content-Type": "application/json;charset=UTF-8"});
    config.cancelToken = new CancelToken((c) => {
        pending.push({ url: config.url, method: config.method, params: config.params, data: config.data, cancel: c });
    });
    return config;
}, error => {
    return Promise.reject(error)
})
instance.interceptors.response.use((data: AxiosResponse) => {
    // 移除重复请求
    removePending(data.config);
    // 请求成功

    if (data.status == 200 || data.status == 204) {
        if(data.data.code == 10010002) {
            ElMessage.warning(data.data.msg);
            setTimeout(() => {
                router.push('/');
                sessionStorage.setItem('userData','')
            }, 500);
            return;
        } else {
            return Promise.resolve(data.data);
        }
    } else {
        return Promise.reject(data.data);
    }
}, error =>{
    const { response } = error;
    if(response) {
        checkStatus(response.status);
        // 超时重新请求
        const config = error.config;
        // 全局的请求次数,请求的间隙
        const [RETRY_COUNT, RETRY_DELAY] = [3, 1000];
        if(config && RETRY_COUNT) {
            // 设置用于跟踪重试计数的变量
            config.__retryCount = config.__retryCount || 0;
            if(config.__retryCount >= RETRY_COUNT) {
                return Promise.reject(response || { message: error.message });
            }
            config.__retryCount++;
            // 创造新的Promise来处理指数后退
            const backoff = new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                }, RETRY_DELAY || 1);
            })
            // instance重试请求的Promise
            return backoff.then(() => {
                return instance(config);
            })
        }
        return Promise.reject(response);
    } else {
        // 处理断网的情况
        // eg:请求超时或断网时，更新state的network状态
        // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
        // 后续增加断网情况下做的一些操作
    }
})
export default instance