
import { defineComponent, reactive, toRefs, watch } from 'vue'
import CutScreen from './components/CutScreen.vue'
import SubmitTips from './components/submitTips.vue'
import SbumitState from './components/submitState.vue'
import { PopupEmitType } from '@/unit/type'

export default defineComponent({
    components: {
        CutScreen,
        SubmitTips,
        SbumitState,
    },
    props: {
        popupType: {
            type: String,
            default: 'CutScreen'
        },
        state: {
            type: String,
            default: 'success'
        }
    },
    setup(props, ctx) {
        const data = reactive({
            popupType: props.popupType
        })
        function close(data: PopupEmitType): void {
            ctx.emit('close',data)
        }
        watch(() => {
            props.popupType;
        },
        () =>{
            data.popupType = props.popupType;
        });
        return {
            close,
            ...toRefs(data),
        }
    },
})
