import axios from '@/unit/http/request'
import { resType } from '@/unit/type'
export function createPaper<T = CreatePaperResData>(params: CreatePaperParams,loading?: boolean, type?: string,setToken?: boolean): Promise<T> {
    return axios.post('/exam/api/paper/paper/create-paper', params, loading , type, setToken)
}
interface CreatePaperParams {
    examId: string
}
interface CreatePaperResData extends resType {
    data: {
        id: string,
    }
}

export function paperDetail<T = PaperDetailResData>(params: PaperDetail,loading?: boolean, type?: string,setToken?: boolean): Promise<T> {
    return axios.post('/exam/api/paper/paper/paper-detail', params, loading , type, setToken)
}
export interface PaperDetailResData extends resType {
    data: paperDetailData
}
type PaperDetail = {
    id: string
}
export interface paperDetailData {
    createTime: string,
	departId: string,
	examId: string,
	hasSaq: boolean,
	id: string,
    judgeList: [{
        actualScore: number,
        answer: string,
        answered: boolean,
        id: string,
        isRight: boolean,
        paperId: string,
        quId: string,
        quType: number,
        score: number,
        sort: number,
        isSign?: boolean,
        isSccomplish?: boolean
    }],
    leftSeconds: number,
    limitTime: string,
    multiList: [{
        actualScore: number,
        answer: string,
        answered: boolean,
        id: string,
        isRight: boolean,
        paperId: string,
        quId: string,
        quType: number,
        score: number,
        sort: number,
        isSign?: boolean,
        isSccomplish?: boolean
    }],
    objScore: number,
    qualifyScore: number,
    radioList: [{
        actualScore: number,
		answer: string,
		answered: boolean,
		id: string,
		isRight: boolean,
		paperId: string,
		quId: string,
		quType: number,
		score: number,
		sort: number,
        isSign?: boolean,
        isSccomplish?: boolean
    }],
    state: number,
    subjScore: number,
    title: string,
    totalScore: number,
    totalTime: number,
	updateTime: string,
	userId: string,
	userScore: number,
	userTime: number
}

export function quDetail<T = QuDetailResData>(params: QuDetailParams,loading?: boolean, type?: string,setToken?: boolean): Promise<T> {
    return axios.post('/exam/api/paper/paper/qu-detail', params, loading , type, setToken)
}
export interface QuDetailParams {
    answer?: string,
    answers?: string[],
    paperId: string | number,
    quId: string | number,
}
interface QuDetailResData extends resType {
    data: QuDetailData
}
export interface QuDetailData {
    actualScore: number,
	answer: string,
	answerList: [
		{
			abc: string,
			answerId: string,
			checked: boolean,
			content: string,
			id: string,
			image: string,
			isRight: boolean,
			paperId: string,
			quId: string,
			sort: number
		}
	],
	answersList?: boolean[],
	answered: boolean,
	content: string,
	id: string,
	image: string,
	isRight: boolean,
	paperId: string,
	quId: string,
	quType: number,
	score: number,
	sort: number
}

export function fillAnswer<T = FillAnswerResData>(params: FillAnswerParams,loading?: boolean, type?: string,setToken?: boolean): Promise<T>  {
    return axios.post('/exam/api/paper/paper/fill-answer', params, loading , type, setToken)
}
interface FillAnswerResData extends resType {
    data: {
		actualScore: number,
		answer: string,
		answerList: [
			{
				abc: string,
				answerId: string,
				checked: boolean,
				content: string,
				id: string,
				image: string,
				isRight: boolean,
				paperId: string,
				quId: string,
				sort: number
			}
		],
		answered: boolean,
		content: string,
		id: string,
		image: string,
		isRight: boolean,
		paperId: string,
		quId: string,
		quType: number,
		score: number,
		sort: number
	},
}
interface FillAnswerParams {
    answer: string,
	answers: string[],
	paperId: number,
	quId: number
}

export function handExam< T = resType>(params: HandExamParams,loading?: boolean, type?: string,setToken?: boolean): Promise<T> {
	return axios.post('/exam/api/paper/paper/hand-exam', params, loading , type, setToken)
}
interface HandExamParams {
	id: number
}